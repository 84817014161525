<template>
    <form>
        <slot></slot>
        <div style="display: none !important;">
            <input type="email" name="_validate_email" id="_validate-email">
        </div>
        <label for="subject" class="md:mx-3/16 mb-16 block font-serif font-bold text-2xl">
            Votre demande <br>concerne* :
        </label>
        <div class="w-full md:w-10/16 lg:w-4/16 md:mx-3/16 mb-12 form-control form-select">
            <select name="subject" id="subject" required v-model="subject">
                <option value="" disabled>Choisir...</option>
                <option v-for="(label, value) in subjects" :value="value">{{ label }}</option>
            </select>
            <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('subject')">{{ error }}</p>
        </div>

        <template v-if="subject">
            <div class="flex flex-wrap" v-if="subject === 'workshop_booking'">
                <div class="w-full lg:w-4/16 md:mx-3/16 lg:mr-0 mb-12 form-control form-select">
                    <select name="workshop" id="workshop" required v-model="workshop">
                        <option value="" disabled>Date*</option>
                        <option v-for="w in workshops" :value="w.id">{{ w.date }}</option>
                    </select>
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('workshop')">{{ error }}</p>
                </div>

                <div class="w-full lg:w-4/16 md:mx-3/16 lg:ml-2/16 mb-12">
                    <input type="number"
                           name="participants_number"
                           key="participantsNumber"
                           id="participants-number"
                           min="1"
                           step="1"
                           placeholder="Nombre de participants*"
                           required
                           class="form-control"
                           :value="old.participants_number">
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('participants_number')">
                        {{ error }}
                    </p>
                </div>
            </div>

            <template v-if="subject === 'product_info'">
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-4/16 md:mx-3/16 lg:mr-0 mb-12 form-control form-select">
                        <select name="product" id="product" required v-model="product">
                            <option value="" disabled>Produit*</option>
                            <option v-for="p in products" :value="p.id">{{ p.title }}</option>
                        </select>
                        <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('product')">
                            {{ error }}
                        </p>
                    </div>

                    <div class="w-full lg:w-4/16 md:mx-3/16 lg:ml-2/16 mb-12">
                        <input type="number"
                               name="quantity"
                               id="quantity"
                               key="quantity"
                               min="1"
                               step="1"
                               required
                               placeholder="Quantité*"
                               class="form-control"
                               v-model="quantity">
                        <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('quantity')">
                            {{ error }}
                        </p>
                    </div>
                </div>

                <div class="w-full md:w-10/16 lg:w-4/16 md:mx-3/16 mb-12 form-control form-select"
                     v-if="selectedProduct && selectedProduct.formats.length">
                    <select name="format" id="format" required v-model="format">
                        <option value="" disabled>Format*</option>
                        <option :value="i" v-for="(format, i) in selectedProduct.formats">{{ format }}</option>
                    </select>
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('format')">
                        {{ error }}
                    </p>
                </div>
            </template>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-4/16 md:mx-3/16 lg:mr-0 mb-12">
                    <input type="text"
                           name="last_name"
                           id="last-name"
                           placeholder="Nom*"
                           required
                           class="form-control"
                           :value="old.last_name">
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('last_name')">
                        {{ error }}
                    </p>
                </div>
                <div class="w-full lg:w-4/16 md:mx-3/16 lg:ml-2/16 mb-12">
                    <input type="text"
                           name="first_name"
                           id="first-name"
                           placeholder="Prénom*"
                           required
                           class="form-control"
                           :value="old.first_name">
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('first_name')">
                        {{ error }}
                    </p>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-4/16 md:mx-3/16 lg:mr-0 mb-12">
                    <input type="tel"
                           name="phone_number"
                           id="phone-number"
                           placeholder="Numéro de téléphone*"
                           required
                           class="form-control"
                           :value="old.phone_number">
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('phone_number')">
                        {{ error }}
                    </p>
                </div>
                <div class="w-full lg:w-4/16 md:mx-3/16 lg:ml-2/16 mb-12">
                    <input type="email"
                           name="email"
                           id="email"
                           placeholder="Adresse email*"
                           required
                           class="form-control"
                           :value="old.email">
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('email')">
                        {{ error }}
                    </p>
                </div>
            </div>

            <div class="mb-12 md:px-3/16">
                <label for="message" class="mb-4 block font-light text-xs tracking-wider uppercase">
                    Votre message<template v-if="subject === 'info_request'">*</template>
                </label>
                <div class="mb-5">
                    <textarea name="message"
                              id="message"
                              rows="4"
                              class="p-3 border form-control"
                              :required="subject === 'info_request'">{{ old.message }}</textarea>
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('message')">
                        {{ error }}
                    </p>
                </div>

                <div class="form-checkbox mb-3">
                    <input type="checkbox" name="subscribe_newsletter" id="subscribe-newsletter" value="1">
                    <label for="subscribe-newsletter">
                        J’accepte de recevoir la newsletter des gascons
                    </label>
                </div>

                <div class="form-checkbox mb-3">
                    <input type="checkbox" name="phone_call_back" id="phone-call-back" value="1">
                    <label for="phone-call-back">
                        J’accepte d'être recontacté par téléphone
                    </label>
                </div>

                <div class="form-checkbox mb-32">
                    <input type="checkbox" name="accept_store_data" id="accept-store-data" value="1" required>
                    <label for="accept-store-data">
                        J’accepte que mes informations soient enregistrées pour le traitement de ma demande*
                    </label>
                    <p class="mt-2 text-xs text-red-light" v-for="error in errors.get('accept_store_data')">
                        {{ error }}
                    </p>
                </div>

                <div class="flex justify-between items-center">
                    <p class="font-serif font-bold text-2xl">Merci.</p>
                    <button type="submit"
                            class="px-16 py-3 bg-black font-serif font-bold text-white text-lg tracking-wide underline">
                        Envoyer
                    </button>
                </div>
            </div>
        </template>
        <p class="md:mx-3/16 text-xs">* champs obligatoires</p>
    </form>
</template>

<script>
    import {Errors} from '../Errors';

    export default {
        name: 'ContactForm',
        props: {
            dataErrors: {
                type: Object,
                default: () => ({}),
            },
            old: {
                type: Object,
                default: () => ({}),
            },
            subjects: {
                type: Object,
                required: true,
            },
            workshops: {
                type: Array,
                default: () => [],
            },
            products: {
                type: Array,
                default: () => [],
            },
            dataProduct: {
                type: [Number, String],
                default: '',
            },
            dataWorkshop: {
                type: [Number, String],
                default: '',
            },
            dataSubject: {
                type: String,
                default: '',
            },
            dataFormat: {
                type: [Number, String],
                default: '',
            },
            dataQuantity: {
                type: [Number, String],
                default: '',
            },
        },
        data: ctx => {
            let format = '';
            if (!Number.isNaN(parseInt(ctx.old.format))) {
                format = parseInt(ctx.old.format);
            } else if (ctx.dataFormat !== '') {
                format = ctx.dataFormat;
            }

            let quantity = '';
            if (!Number.isNaN(parseInt(ctx.old.quantity))) {
                quantity = parseInt(ctx.old.quantity);
            } else if (ctx.dataQuantity !== '') {
                quantity = ctx.dataQuantity;
            }

            return ({
                errors: new Errors(ctx.dataErrors),
                subject: ctx.old.subject || ctx.dataSubject || '',
                workshop: parseInt(ctx.old.workshop) || ctx.dataWorkshop || '',
                product: parseInt(ctx.old.product) || ctx.dataProduct || '',
                format,
                quantity,
            });
        },
        computed: {
            selectedProduct() {
                return this.products.find(p => p.id === this.product);
            },
        },
    };
</script>

<style scoped>

</style>