<template>
    <div class="container">
        <div class="flex flex-wrap px-4 md:px-0 mb-10 md:mb-0">
            <h2 class="w-full md:w-5/16 md:ml-2/16 mb-10 font-serif font-bold text-4xl">{{ title }}</h2>
            <div class="w-full md:w-9/16 flex justify-center items-center font-serif font-bold text-base">
                <span class="mx-12 cursor-pointer"
                      v-for="(item, i) in items"
                      :class="{'opacity-25': i !== currentIndex}"
                      @click="goTo(i)">
                    {{ i + 1 | padStart }}
                </span>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full md:w-7/16">
                <swiper ref="slider">
                    <swiper-slide v-for="(item, i) in items" :key="i"
                                  class="h-auto pl-2/16 pr-1/16 py-16 flex flex-col bg-white">
                        <h3 class="mb-12 uppercase">
                            <small class="font-serif text-xs block">{{ item.time }}</small>
                            <span class="text-base font-light leading-loose tracking-wider">
                                {{ item.title }}
                            </span>
                        </h3>
                        <div class="content mb-auto" v-html="item.description"></div>
                        <div class="w-16 mt-16 flex justify-between items-center text-2xl">
                            <button type="button"
                                    :disabled="i === 0"
                                    :class="{'opacity-50': i === 0}"
                                    @click="prev()">
                                <img src="../../../img/arrow-left.svg" alt="" class="w-5">
                            </button>
                            <button type="button"
                                    :disabled="i === (items.length - 1)"
                                    :class="{'opacity-50': i === (items.length - 1)}"
                                    @click="next()">
                                <img src="../../../img/arrow-right.svg" alt="" class="w-5">
                            </button>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="w-full h-64 md:w-9/16 md:h-auto relative">
                <transition>
                    <img :src="currentImage.url"
                         :alt="currentImage.alt"
                         :key="currentImage.url"
                         class="w-full md:w-8/13-screen h-full max-w-none absolute pin-t pin-l object-cover">
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorkshopSchedule',
        props: {
            title: {
                type: String,
                default: '',
            },
            items: {
                type: Array,
                default: () => [],
            },
        },
        data: () => ({
            currentIndex: 0,
        }),
        computed: {
            currentImage() {
                return this.items[this.currentIndex].image;
            },
        },
        methods: {
            prev() {
                this.$refs.slider.swiper.slidePrev();
            },
            next() {
                this.$refs.slider.swiper.slideNext();
            },
            goTo(index) {
                this.$refs.slider.swiper.slideTo(index);
            },
        },
        mounted() {
            this.$refs.slider.swiper.on('slideChange', () => {
                this.currentIndex = this.$refs.slider.swiper.activeIndex;
            });
        },
    };
</script>

<style scoped>
    img.v-enter-active, img.v-leave-active {
        transition: opacity 0.3s ease-in-out;
    }
    img.v-enter, img.v-leave-to {
        opacity: 0;
    }
</style>