import Vue from 'vue';
import VueTabs from 'vue-tabs-component';
import {
    ContactForm,
    Dropdown,
    HomeProductCategories,
    NewsletterForm,
    ProductGallery, ProductPrice,
    Swiper,
    SwiperSlide,
    WorkshopSchedule,
    YoutubePlayer,
} from './components';
import circleText from './directives/circle-text';

Vue.use(VueTabs);

Vue.component(HomeProductCategories.name, HomeProductCategories);
Vue.component(Dropdown.name, Dropdown);
Vue.component(Swiper.name, Swiper);
Vue.component(SwiperSlide.name, SwiperSlide);
Vue.component(ProductGallery.name, ProductGallery);
Vue.component(ProductPrice.name, ProductPrice);
Vue.component(YoutubePlayer.name, YoutubePlayer);
Vue.component(WorkshopSchedule.name, WorkshopSchedule);
Vue.component(NewsletterForm.name, NewsletterForm);
Vue.component(ContactForm.name, ContactForm);

Vue.directive('circle-text', circleText);

Vue.filter('padStart', (value, length = 2, fillString = '0') => {
    return value.toString().padStart(length, fillString);
});
Vue.filter('numberFormat', (value, options) => {
    return value.toLocaleString('fr', options);
});

new Vue({
    el: '#app',
    data: () => ({
        menuVisible: window.innerWidth >= 768,
        menuButtonClicked: false,
    }),
    computed: {
        productFormats() {
            if (this.contactForm.product in this.contactForm.productsFormats) {
                return this.contactForm.productsFormats[this.contactForm.product];
            }
            return [];
        },
    },
    methods: {
        toggleMenu() {
            this.menuButtonClicked = true;
            this.$nextTick(() => {
                this.menuVisible = !this.menuVisible;
                document.documentElement.classList.toggle('overflow-hidden', this.menuVisible);
            });
        },
    },
    mounted() {
        window.addEventListener('resize', () => this.menuVisible = window.innerWidth >= 768);
    },
});
