<template>
    <section class="bg-grey-light md:-mt-32 pt-32 md:pt-64">
        <div class="container">
            <h2 class="font-serif text-4xl md:ml-1/16 mb-10 px-4 md:pl-1/16">{{ title }}</h2>
            <div class="flex">
                <div class="w-2/3 md:w-7/16 md:ml-1/16 flex-no-shrink px-2/16 md:px-1/16 py-24 bg-white text-base tracking-wide">
                    <ol class="list-reset -my-16">
                        <li class="my-16" v-for="(category, index) in categories" :key="category.id">
                            <a :href="category.url" class="block no-underline text-grey-darker"
                               @mouseover="activateCategory(category)">
                                <small class="block mb-2 text-xs">{{ index + 1 | padStart }}</small>
                                {{ category.title }}
                            </a>
                        </li>
                    </ol>
                </div>
                <div class="overflow-hidden w-1/3 md:w-1/2-screen flex-no-shrink relative">
                    <transition :name="`home-${direction}-product-category-image`">
                        <img :src="activeCategory.image"
                             :alt="activeCategory.imageAlt"
                             :key="activeCategory.image"
                             class="w-full h-full absolute pin-t pin-l object-cover">
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import debounce from 'lodash.debounce';

    export default {
        name: 'HomeProductCategories',
        props: {
            title: {
                type: String,
                default: '',
            },
            categories: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                activeCategory: this.categories[0],
                direction: 'next',
            };
        },
        methods: {
            activateCategory: debounce(function (category) {
                let activeIndex = this.categories.indexOf(this.activeCategory);
                let newIndex = this.categories.indexOf(category);
                this.direction = newIndex > activeIndex ? 'next' : 'prev';
                this.activeCategory = category;
            }, 100),
        },
    };
</script>
