<template>
    <form class="w-full lg:w-6/16 lg:mx-1/16" @submit.prevent="submit()">
        <label for="email" class="block mb-6 uppercase text-xs">
            Recevez notre actualité cochons
        </label>
        <div class="flex">
            <input type="email"
                   id="email"
                   class="flex-1 p-4 md:px-6 bg-transparent border"
                   placeholder="Votre adresse email"
                   v-model="email">
            <button type="submit" class="btn btn-black btn-lg font-light text-xs md:text-sm uppercase" :disabled="loading">
                Envoyer
            </button>
        </div>
        <p v-if="message" class="mt-4">{{ message }}</p>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'NewsletterForm',
        props: {
            nonce: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            email: '',
            message: '',
            loading: false,
        }),
        methods: {
            submit() {
                const params = new URLSearchParams();
                params.append('action', 'subscribe_newsletter');
                params.append('_wpnonce', this.nonce);
                params.append('email', this.email);
                this.loading = true;

                return axios.post(LesGascons.adminAjax, params)
                    .then(() => {
                        this.message = 'Votre inscription a bien été prise en compte.';
                        this.email = '';
                    })
                    .catch(() => {
                        this.message = 'Une erreur est survenue.';
                    })
                    .finally(() => this.loading = false);
            },
        },
    };
</script>

<style scoped>

</style>