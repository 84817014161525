<template>
    <div class="relative">
        <button type="button" class="h-full select flex items-center"
                @click="visible = !visible"
                @blur="onBlur">
            <slot name="button"></slot>
            <span :class="{'carret-up': visible, 'carret-down': !visible}" class="inline-block ml-2"></span>
        </button>
        <div class="w-full absolute pin-t-full pin-l z-10 m-0 -mt-px bg-white border border-grey-darker"
             v-show="visible && $scopedSlots.menu()">
            <slot name="menu"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dropdown',
        props: {
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        data: () => ({
            visible: false,
        }),
        methods: {
            onBlur() {
                setTimeout(() => this.visible = false, 100);
            },
        },
    };
</script>
