<template>
    <div>
        <div class="flex justify-between items-end mb-12">
            <div>
                <label for="format" class="block mb-2">Format :</label>
                <div class="select-wrapper">
                    <select id="format" name="format" required v-model="selectedFormatIndex">
                        <option v-for="(format, i) in formats" :value="i">
                            {{ format.label }} - {{ format.price | numberFormat({style: 'currency', currency: 'EUR'}) }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <label for="quantity" class="block mb-2">Quantité :</label>
                <input type="number"
                       id="quantity"
                       name="quantity"
                       min="1"
                       step="1"
                       class="w-16 p-2 border-b"
                       v-model.number="quantity"
                       required>
            </div>
        </div>
        <p class="font-serif font-light text-4xl mb-8" v-if="selectedFormat">
            {{ selectedFormat.price * quantity | numberFormat({style: 'currency', currency: 'EUR'}) }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ProductPrice',
        props: {
            formats: {
                type: Array,
                default: () => [],
            },
        },
        data: () => ({
            selectedFormatIndex: 0,
            quantity: 1,
        }),
        computed: {
            selectedFormat() {
                return this.formats[this.selectedFormatIndex];
            },
        },
    };
</script>
