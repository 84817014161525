<template>
    <div>
        <swiper :options="mainSliderOptions" v-if="showMainSlider" class="mb-3">
            <swiper-slide v-for="(image, index) in images" :key="index">
                <img :src="image.url" :alt="image.alt" class="w-full block cursor-pointer">
            </swiper-slide>
        </swiper>
        <swiper :options="thumbnailSliderOptions" ref="thumbnailSlider">
            <swiper-slide v-for="(image, index) in images" :key="index" class="w-auto">
                <img :src="image.thumbnail" :alt="image.alt" class="block cursor-pointer">
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    export default {
        name: 'ProductGallery',
        props: {
            images: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            let vm = this;
            return {
                showMainSlider: false,
                mainSliderOptions: {
                    thumbs: {
                        swiper: null,
                    },
                },
                thumbnailSliderOptions: {
                    slidesPerView: 3,
                    slideToClickedSlide: true,
                    on: {
                        init() {
                            vm.mainSliderOptions.thumbs.swiper = this;
                            vm.showMainSlider = true;
                        },
                    },
                },
            };
        },
    };
</script>
