<template>
    <div class="youtube-player-block relative px-2/16 mb-8">
        <div class="bg-grey-lighter w-screen h-1/2 absolute pin-b pin-l md:ml-1/16"></div>
        <div class="ratio-16/9 relative">
            <img :src="thumbnail" :alt="title" class="w-full h-full object-cover absolute pin" v-if="thumbnailVisible">
            <iframe :src="src"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="absolute pin w-full h-full"
                    v-else>
            </iframe>
        </div>
        <div class="absolute pin flex justify-center items-center" v-if="thumbnailVisible">
            <button class="font-serif font-bold text-5xl text-white" type="button" @click="thumbnailVisible = false">
                Lire la vidéo
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'YoutubePlayer',
        props: {
            src: {
                type: String,
                required: true,
            },
            thumbnail: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
        },
        data: () => ({
            thumbnailVisible: true,
        }),
    };
</script>