<template>
    <div class="swiper-slide">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'SwiperSlide',
    };
</script>
