<template>
    <div class="swiper-container">
        <slot name="buttons" v-if="navButtons">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </slot>
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';

    export default {
        name: 'Swiper',
        props: {
            navButtons: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        mounted() {
            this.swiper = new Swiper(this.$el, this.options);
        },
    };
</script>
