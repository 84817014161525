import charming from 'charming';

export default {
    /**
     * @param {HTMLElement} el
     * @param angleSpan
     * @param angleOffset
     * @param radius
     */
    bind(el, {value: {angleSpan = 180, angleOffset = 0, radius = 200} = {}}) {
        el.innerText = el.innerText.trim();
        charming(el, {classPrefix: false});
        el.classList.add('circle-text');
        el.style.width = `${radius * 2}px`;
        el.style.height = `${radius * 2}px`;
        let letters = Array.from(el.querySelectorAll('span'));
        letters.forEach((letter, i) => {
            letter.style.height = `${radius}px`;
            letter.style.left = `${radius - 10}px`;
            letter.style.transform = `rotate(${angleOffset + angleSpan / (letters.length - 1) * i}deg)`;
        });
    },
};